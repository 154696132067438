.topVideoCon {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  height: 41vh;
  width: 100%;
}

.topVideoCon > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.onBoardContent {
  gap: 4rem;
  padding: 80px 50px;
  z-index: 2;
  position: relative;
  width: 90%;
  margin: auto;
}

.onBoardImg > img {
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  object-fit: cover;
  height: 100%;
}

.onBoardText {
  padding: 1rem;
  border-bottom-left-radius: 2rem;
  color: rgb(0, 13, 16);
  text-align: center;
}

.heading {
  font-size: 2.7rem;
  font-weight: 600;
  color: rgb(0, 13, 16);
  margin-bottom: 1.1rem;
  text-align: center;
}

.subHeading {
  font-size: 2.7rem;
  font-weight: 700;
  color: rgb(0, 13, 16);
  margin-top: 1.6rem;
  line-height: 3rem;
}

.ctaHeading {
  font-size: 2.7rem;
  font-weight: 700;
  color: rgb(0, 13, 16);
  line-height: 3rem;
  margin-top: 1.7rem;
}

.paragraph {
  
  line-height: 3rem;
  margin-bottom: 5rem;
  font-weight: 400;
  color: rgb(0, 13, 16);
  font-size: 2rem;
}

@media  (max-width:601px) {
  .topVideoCon {
  height: 31vh;
  }
  
  .onBoardContent {
    padding: 30px 10px;
    z-index: 2;
    position: relative;
    width: 90%;
    margin: auto;
  }
}