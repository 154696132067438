.loginContainer {
  padding: 3.5rem;
}
.loginContainer > .header {
  color: rgb(0, 13, 16);
  font-size: 3.3rem;
  line-height: 3.3rem;
  margin-bottom: 1.5rem;
}

.loginFormContainer {
  display: grid;
  grid-template-columns: repeat(2, 30%);
  column-gap: 10rem;
}
.businessesCont {
  /* border-right: 1px solid rgba(0, 0, 0, 0.304); */
}
.businessesCont > h3,
.wellBeingCont > h3 {
  font-size: 2rem;
  color: rgb(0, 13, 16);
  margin-bottom: 1rem;
}
.confirm {
  margin: 3rem 0px;
  cursor: pointer;
}
.confirm > span:nth-child(1) {
  font-size: 1.1rem;
  color: rgb(142, 142, 149);
  font-weight: 700;
}
.confirm > span:nth-child(2) {
  font-size: 1.1rem;
  color: rgb(188, 113, 85);
  font-weight: 700;
}
.confirm span:hover {
  text-decoration: underline;
}

@media screen and (max-width: 800px) {
  .loginFormContainer {
    display: grid;
    grid-template-columns: repeat(2, 55%);
    column-gap: 10rem;
  }
}
@media screen and (max-width: 601px) {
  .loginFormContainer {
    display: grid;
    grid-template-columns: repeat(2, 90%);
    column-gap: 10rem;
  }
}
