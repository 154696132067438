.navContainer {
  min-height: 41vh;
}

.navcont {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 40px;
  z-index: 2;
}
.tabsCont {
  display: flex;
  align-items: center;
}
.tabsCont > h5 {
  display: inline-block;
  position: relative;
  padding-bottom: 5px;
  cursor: pointer;
}

.tabsCont > h5::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #000d10;
  transition: width 0.3s ease;
}

.tabsCont > h5:hover::after {
  width: 100%;
}
.tabsCont > h5 {
  margin-right: 40px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #000d10;
}

.active {
  color: white !important;
}

.active::after {
  width: 100%;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white !important;
  transition: width 0.3s ease;
}
.active:hover::after {
  width: 100%;
}
.logoCont {
  width: 10rem;
  height: 10rem;
  position: absolute;
  left: 10px;
  top: 0px;
}
.logoCont > img {
  cursor: pointer;
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(213, 211, 212);
}

.title {
  font-size: 3.2rem;
  color: rgb(0, 13, 16);
  text-align: center;
}

.drawerBody {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 0px 25px;
  gap: 1rem;
  margin-top: 2rem;
}
.section1_sub1_item {
  cursor: pointer;
}
.section1_sub1_item > h3 {
  font-size: 1.4rem;
  color: rgb(0, 13, 16);
  padding: 0.5rem 0.2rem;
}
.section1_sub1_item > div > p {
  font-weight: 400;
  color: rgb(0, 13, 16);
  font-size: 1.1rem;
}
.webTitle {
  display: flex;
  justify-content: center;
  padding: 0px 5rem;
  position: relative;
  z-index: 1000;
  top: 20vh;
}

@media (max-width: 601px) {
  .logoCont {
    left: 10px;
    top: 20px;
  }
  .navcont {
    padding: 40px 20px;
  }

  .drawerHeader {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    align-items: center;
  }
  .tabsCont > h5 {
    display: none;
  }

  .drawerBody {
    padding: 0px 25px;
  }
  .webTitle {
    top: 15vh;
  }
}

.section1_sub1_item {
  width: 60%;
  transition: background-color 0.3s, transform 0.3s;
}

.section1_sub1_item:hover {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.section1_sub1_item h3 {
  transition: color 0.3s;
}

.section1_sub1_item:hover h3 {
  opacity: 0.5;
}
