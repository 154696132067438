.authContainer {
  height: fit-content;
}

.authContainer > .authHeader {
  border-bottom-right-radius: 5rem;
  background-color: #000d10 !important ;
  padding: 3.5rem;
  padding-bottom: 1.5rem;
}
.authHeader_sub1 {
  display: flex;
  margin-bottom: 3.5rem;
  cursor: pointer;
}
.authHeader_sub1,
.authHeader_sub1 > p {
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.authHeader_sub1 > p {
  margin-left: 20px;
}
.authHeader_sub2 {
  font-size: 2rem;
  color: rgb(255, 255, 255);
}
