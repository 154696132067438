.authContainer {
  height: fit-content;
}

.authContainer > .authHeader {
  border-bottom-right-radius: 5rem;
  background-color: #000d10 !important ;
  padding: 3.5rem;
  padding-bottom: 1.5rem;
}
.authHeader_sub1 {
  display: flex;
  margin-bottom: 3.5rem;
  cursor: pointer;
}
.authHeader_sub1,
.authHeader_sub1 > p {
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  font-weight: 400;
}
.authHeader_sub1 > p {
  margin-left: 20px;
}
.authHeader_sub2 {
  font-size: 2rem;
  color: rgb(255, 255, 255);
}

.registerContainer {
  padding: 3.5rem;
}
.registerContainer > .header {
  color: rgb(0, 13, 16);
  font-size: 3.3rem;
  line-height: 3.3rem;
  margin-bottom: 3rem;
}
.registerFormContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10rem;
}

.businessesCont > h3,
.wellBeingCont > h3 {
  font-size: 2rem;
  color: rgb(0, 13, 16);
  margin-bottom: 1rem;
}

.confirm {
  padding: 2rem 0px;
  cursor: pointer;
}
.confirm > span:nth-child(1) {
  font-size: 1.1rem;
  color: rgb(142, 142, 149);
  font-weight: 700;
}
.confirm > span:nth-child(2) {
  font-size: 1.1rem;
  color: rgb(188, 113, 85);
  font-weight: 700;
}
.confirm span:hover {
  text-decoration: underline;
}
.formContainer {
  display: flex;
  justify-content: center;
}
.imgCont {
  margin-right: 3rem;
  border-right: 1px solid rgb(213, 211, 212);
  padding-right: 3rem;
  width: 50%;
}
.formContainerSec {
  width: 50%;
}
.subHeader {
  font-size: 2rem;
  color: rgb(0, 13, 16);
  text-align: center;
}
.subHeaderCont {
  margin-bottom: 1rem;
}

@media screen and (max-width: 800px) {
  .formContainerSec {
    width: 80%;
  }
}
@media  (max-width:601px) {
  .formContainerSec {
    width: 100%;
  }
}