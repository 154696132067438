
.App{
  width: 100%;
  
}
#animation{
  overflow: hidden;
}
#animation canvas{
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    min-height: 10rem;
    /* overflow: hidden; */

}









