.activityCont {
  padding: 3rem;
}
.activitySec_1 {
  display: flex;
  flex-direction: column;
  padding: 0rem 4rem;
}
.activity_1,
.activity_2,
.activity_3 {
  height: 18rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgb(213, 211, 212);
  column-gap: 5rem;
  width: 90%;
  height: fit-content;
}
.activity_1 > .productsModulesImg,
.activity_2 > .productsModulesImg,
.activity_3 > .productsModulesImg {
  width: 100%;
  height: 18rem;
}
.activity_1 > .productsModulesImg > img,
.activity_2 > .productsModulesImg > img,
.activity_3 > .productsModulesImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}
.topVideoCon {
  position: absolute;
  top: 0px;
  z-index: 1;
  left: 0px;
  height: 41vh;
  width: 100%;
}
.topVideoCon > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activityCont > .header {
  position: relative;
  margin-bottom: 2rem;
  color: rgb(0, 13, 16);
}
.activity_1 > .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.activity_2 > .info,
.activity_3 > .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.activity_1 > .info > .paragraph,
.activity_2 > .info > .paragraph,
.activity_3 > .info > .paragraph {
  font-weight: 400;
  font-size: 1.1rem;
  color: rgb(0, 13, 16);
}
.subParagraph {
  font-weight: 400;
  font-size: 1.1rem;
  color: rgb(0, 13, 16);
  margin-bottom: 0.5rem;
}
.activity_2,
.activity_3 {
  margin-top: 1.5rem;
}
.retreats {
  margin-top: 1rem;
}
.retreats > h3 {
  margin-bottom: 0.3rem;
  color: rgb(0, 13, 16);
}
.activity_list > li {
  font-size: 1rem;
  margin-bottom: 0.3rem;
  font-weight: 400;
  color: rgba(0, 13, 16, 0.761);
}
.activity_1 > .info > .header,
.activity_2 > .info > .header,
.activity_3 > .info > .header {
  font-size: 1.7rem;
  color: rgb(0, 13, 16);
  margin-bottom: 1.2rem;
}
@media (max-width: 601px) {
  .topVideoCon {
    height: 31vh;
  }
  .activitySec_1 {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
  }
  .activity_1,
  .activity_2,
  .activity_3 {
    width: 100%;
  }
}
