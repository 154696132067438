@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ThinIta.eot");
  src: local("Helvetica Now Display Thin Ita"),
    local("HelveticaNowDisplay-ThinIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ThinIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ThinIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ThinIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ThinIta.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Light.eot");
  src: local("Helvetica Now Display Light"), local("HelveticaNowDisplay-Light"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Light.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Light.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BoldIta.eot");
  src: local("Helvetica Now Display Bold Ita"),
    local("HelveticaNowDisplay-BoldIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BoldIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BoldIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BoldIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BoldIta.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-RegIta.eot");
  src: local("Helvetica Now Display Reg Ita"),
    local("HelveticaNowDisplay-RegIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-RegIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-RegIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-RegIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-RegIta.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLtIta.eot");
  src: local("Helvetica Now Display ExtLt Ita"),
    local("HelveticaNowDisplay-ExtLtIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLtIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLtIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLtIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLtIta.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-MedIta.eot");
  src: local("Helvetica Now Display Med Ita"),
    local("HelveticaNowDisplay-MedIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-MedIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-MedIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-MedIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-MedIta.ttf")
      format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLt.eot");
  src: local("Helvetica Now Display ExtLt"), local("HelveticaNowDisplay-ExtLt"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLt.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLt.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLt.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtLt.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlk.eot");
  src: local("Helvetica Now Display ExtBlk"),
    local("HelveticaNowDisplay-ExtBlk"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlk.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlk.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlk.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlk.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Bold.eot");
  src: local("Helvetica Now Display Bold"), local("HelveticaNowDisplay-Bold"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Bold.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Bold.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBdIta.eot");
  src: local("Helvetica Now Display ExtBd Ita"),
    local("HelveticaNowDisplay-ExtBdIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBdIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBdIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBdIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBdIta.ttf")
      format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Regular.eot");
  src: local("Helvetica Now Display Regular"),
    local("HelveticaNowDisplay-Regular"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Regular.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Regular.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlkIta.eot");
  src: local("Helvetica Now Display ExBlk It"),
    local("HelveticaNowDisplay-ExtBlkIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlkIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlkIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlkIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtBlkIta.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Thin.eot");
  src: local("Helvetica Now Display Thin"), local("HelveticaNowDisplay-Thin"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Thin.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Thin.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-LightIta.eot");
  src: local("Helvetica Now Display Light Ita"),
    local("HelveticaNowDisplay-LightIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-LightIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-LightIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-LightIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-LightIta.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtraBold.eot");
  src: local("Helvetica Now Display ExtBd"),
    local("HelveticaNowDisplay-ExtraBold"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtraBold.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtraBold.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-ExtraBold.ttf")
      format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Medium.eot");
  src: local("Helvetica Now Display Medium"),
    local("HelveticaNowDisplay-Medium"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Medium.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Medium.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-HairlineI.eot");
  src: local("Helvetica Now Display Hairline"),
    local("HelveticaNowDisplay-HairlineI"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-HairlineI.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-HairlineI.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-HairlineI.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-HairlineI.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BlackIta.eot");
  src: local("Helvetica Now Display Black Ita"),
    local("HelveticaNowDisplay-BlackIta"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BlackIta.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BlackIta.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BlackIta.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-BlackIta.ttf")
      format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Black.eot");
  src: local("Helvetica Now Display Black"), local("HelveticaNowDisplay-Black"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Black.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Black.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Hairline.eot");
  src: local("Helvetica Now Display Hairline"),
    local("HelveticaNowDisplay-Hairline"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Hairline.eot?#iefix")
      format("embedded-opentype"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Hairline.woff2")
      format("woff2"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Hairline.woff")
      format("woff"),
    url("../public/assets/helvetica-now-display/HelveticaNowDisplay-Hairline.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
}


body,
* {
  margin: 0;
  font-family: "Helvetica Now Display", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-sizing: border-box; */
}
p,
span,
li {
  font-weight: 300;
}


body {
    background: linear-gradient(to bottom,
        #b3c7d6,
        #e6f1f5,
        #f0f8ff,
        #c9e0f7) !important;
  };

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  max-width: 100%;
  overflow-wrap: break-word; /* Prevent text overflow */
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 3s forwards; /* Fade-in effect */
}

.fadeInDelayed {
  animation: fadeIn 5s 1s forwards; /* Fade-in with a delay */
  animation-delay: 0.2s;
}

html {
  font-size: 1.1vw !important; 
}

/* @media (max-width: 1024px) {
  html {
    font-size: 16px!important; 
  }
} */
@media (max-width: 768px) {
  html {
    font-size: 12px !important;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 2vw !important;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Define the falling animation */
@keyframes falling {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply the falling animation */
.falling {
  animation: falling 1s ease-out forwards;
  /* animation-delay: 1s;  */
}

.customBtn {
  color: white !important;
  border-radius: 30px !important;
  padding: 12px 22px !important;
  font-size: 0.9rem !important;
  font-weight: 700 !important;
}
.hoverActive:hover {
  opacity: 0.8 !important;
}
.customBtn:hover {
  opacity: 0.8 !important;
}
.skeleton-loader {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 25%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.6) 75%
  );
  background-size: 200% 100%;
  animation: wave 1.5s ease-in-out infinite;
}

@keyframes wave {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}
.onlyMobile{
  display: none!important;
}
.loading-animation {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media  (max-width:601px) {
  .customBtn {
    padding: 1.4rem 1.5rem !important;

  }
  .onlyMobile{
    display: block!important;
  }
}