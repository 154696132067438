.section1_sub1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  column-gap: 3.5rem;

  margin-bottom: 60px;
}
.section1_sub1_item {
  border-top: 1px solid rgb(213, 211, 212);
}
.section1_sub1_item > h3 {
  font-size: 1.4rem;
  padding: 25px 0px;
  color: rgb(0, 13, 16);
}
.section1_sub1_item > div > p {
  color: rgb(142, 142, 149);
  font-size: 1.2rem;
  text-align: start;
  margin-bottom: 2.5rem;
  font-weight: 400;
}
.navBtnsec {
  display: flex;
  justify-content: space-between;
  margin: 40px;
  padding: 40px 0px;
  align-items: center;
  width:"100%"
}
.navBtns {
  margin-right: 8vw;
  display: flex;
  justify-content: center;
}

.title {
  width: 30vw;
}

@media (max-width: 601px) {
  .section1_sub1 {
    width: 88%;
  }
}
