.topVideoCon {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  height: 41vh;
  width: 100%;
}

.topVideoCon > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.onBoardContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  padding: 3rem 13rem;
  z-index: 2;
  position: relative;
}
.onBoardImg {
  display: flex;
  align-items: center;
}
.onBoardImg > img {
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  object-fit: contain;
}

.onBoardText {
  padding: 1rem;
  border-bottom-left-radius: 2rem;
  font-size: 1.4rem;
  line-height: 2.5rem;
  color: rgb(0, 13, 16);
  text-align: justify;
}

.heading {
  font-size: 1.8rem;
  font-weight: 600;
  color: #0056b3;
  color: rgb(10, 18, 20);
  margin-bottom: 1rem;
}

.subHeading {
  font-size: 1.4rem;
  font-weight: 500;
  color: #0073e6;
  color: rgb(5, 10, 12);
  margin-top: 1.5rem;
}

.ctaHeading {
  font-size: 1.6rem;
  font-weight: 600;
  color: rgb(15, 25, 30);
  margin-top: 1.5rem;
}

.paragraph {
  font-size: 1.1rem;
  line-height: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 400;
  color: rgb(25, 38, 44);
}

@media screen and (max-width: 800px) {
  .onBoardContent {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
  .onBoardImg {
    max-height: 55vh;
  }
  .onBoardImg > img {
    width: 100%;
  }
  .onBoardText {
    height: fit-content;
  }
}

@media (max-width: 601px) {
  .topVideoCon {
    height: 31vh;
  }
  .onBoardContent {
    padding: 3rem 4rem;
    gap: 3rem;
  }
}
