.footerContainer {
  padding: 40px;
  background-color: rgb(0, 13, 16);
}
.logoSection {
  margin-bottom: 40px;
}

.contactSection {
  display: flex;
  flex-wrap: wrap;
}
.socialMediaCont {
  width: 30%;
}
.socialMediaCont > h2 {
  color: white;
  margin-bottom: 20px;
  font-size: 1.4rem;
}
.socialMediaIcons {
  display: flex;
  gap: 20px;
}
.policySection {
}
.contactInfoCont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  width: 70%;
}
.contactInfo > h2 {
  color: white;
  margin-bottom: 15px;
  font-size: 1.4rem;
}
.contactInfo > p {
  color: #ffffffbf;
  font-size: 1.2rem;
  padding-bottom: 15px;
  cursor: pointer;
  font-weight: 400;
}
.contactInfo > p:hover {
  color: white;
}
.footerLogo {
  width: 4rem;
  height: 4rem;
  border-radius: 10px;
  cursor: pointer;
}
.footerLogo > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
}

@media (max-width: 601px) {
  .footerContainer {
    padding: 30px;
  }
  .logoSection {
    margin-bottom: 20px;
  }
  .contactSection {
    flex-direction: column;
    gap: 20px;
  }
  .contactInfo > h2 {
    white-space: nowrap;
  }
}
